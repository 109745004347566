import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import RequestCallback from "./RequestCallback";

// Image Imports

import logo from "../../../images/logo.png";
import x from "../../../images/x.svg";

// End of Image Imports

const initialValues = {
  email: "",
  timestamp: "",
};

function Footer() {
  // Initial Values

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialValues);
  const { email, timestamp } = values;

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    event.persist();

    setValues({ ...values, [name]: value });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (email === "") {
      return toast.error("Please give us your email", {
        id: "email",
      });
    } else if (!validateEmail(email)) {
      return toast.error("Seems like an invalid email", {
        id: "email",
      });
    }
    const data = new FormData();
    data.append("Email", data[email]);
    data.append("Timestamp", data[timestamp]);

    const form = document.getElementById("contact_form_email");

    setLoading(true);
    fetch(form.action, {
      method: "POST",
      body: new FormData(document.getElementById("contact_form_email")),
    })
      .then((response) => response.json())
      .then(async () => {
        setValues(initialValues);
        setLoading(false);
        toast.success(" Thanks for your email! We will contact you shortly!");
      });
  };

  // Modal
  const [modal, setModal] = useState(false);

  //  Toggle Modal
  const toggle = () => setModal(!modal);
  return (
    <>
      <footer className="footer">
        <div className="container">
          <Row>
            <Col sm="12" lg="5" md="5">
              <div className="logo">
                <img src={logo} alt="Logo" />
              </div>
              <div className="logo-text">
                <h5>Fish2shark Inc.</h5>
                <p>
                  We are a Marketing Agency based out of Ontario, Canada
                  offering a plethora of offline and online marketing solutions
                  for your business.
                </p>
              </div>
              {/* <h4>CONTACT INFO</h4> */}
              <ul className="footer-contact-info-wrap">
                <li>
                  <a
                    href="tel:+15488883355"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <i class="bx bx-phone-call"></i>
                    <h6>+1 (548) 888-3355</h6>
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:support@fish2shark.com"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <i class="bx bx-envelope"></i>
                    <h6>support@fish2shark.com</h6>
                  </a>
                </li>
                <li>
                  <a
                    href="https://maps.app.goo.gl/cbUV9XK2V1iqcpC4A"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <i class="bx bxs-map-pin"></i>
                    <h6>Ontario, Canada</h6>
                  </a>
                </li>
              </ul>
            </Col>
            <Col sm="12" lg="3" md="3">
              <h4>Quick Links</h4>
              <ul className="footer-links-info-wrap">
                <li>
                  <Link to="/">
                    Home
                    <i class="bx bx-right-arrow-alt"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/services">
                    Services <i class="bx bx-right-arrow-alt"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/about">
                    About us <i class="bx bx-right-arrow-alt"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    Contact us <i class="bx bx-right-arrow-alt"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/careers">
                    Careers <i class="bx bx-right-arrow-alt"></i>
                  </Link>
                </li>
                <li>
                  <Link onClick={toggle}>
                    Request a call <i class="bx bx-right-arrow-alt"></i>
                  </Link>
                </li>
              </ul>
            </Col>
            <Col sm="12" lg="4" md="6">
              <h4>FOR INTERNSHIPS</h4>
              <ul className="footer-links-info-wrap">
                <li>
                  <h6>
                    Send your resumes to{" "}
                    <a href="mailto:support@fish2shark.com">
                      <span>support@fish2shark.com</span>
                    </a>
                  </h6>
                </li>
              </ul>
              <div className="connect-input">
                <h4>CONNECT WITH US</h4>
                <form
                  id="contact_form_email"
                  action="https://sheetdb.io/api/v1/8sidz5qcnzfp0?sheet=email"
                  name="contact_form_email"
                  method="POST"
                  onSubmit={submitHandler}
                >
                  <div className="connect-inp">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      placeholder="Enter Email Address"
                      onChange={handleChange}
                    />
                    <input
                      hidden
                      type="text"
                      value={moment().format("DD-MM-YYYY hh:mm a")}
                      name="timestamp"
                      onChange={handleChange}
                    />
                    <button className="footer-connect-btn" type="submit">
                      {loading ? "Connecting..." : "Connect"}
                    </button>
                  </div>
                </form>
              </div>
              <ul className="footer-social-links">
                <a
                  href="https://wa.me/+15488883355"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <li>
                    <i class="bx bxl-whatsapp"></i>
                  </li>
                </a>
                <a
                  href="https://www.facebook.com/Fish2shark/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <li>
                    <i class="bx bxl-facebook-circle"></i>
                  </li>
                </a>
                <a
                  href="https://www.instagram.com/fish2shark2024/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <li>
                    <i class="bx bxl-instagram"></i>
                  </li>
                </a>
                {/* <li>
                  <img src={x} alt="X" />
                </li> */}
              </ul>
            </Col>
          </Row>
        </div>
        <div className="footer-bottom">
          <p className="copy-text">
            {new Date().getFullYear()} &copy; fish2shark Inc. | All Rights
            Reserved <span>&#x2022;</span>{" "}
            <Link to="/legal">Terms and Conditions</Link>
          </p>
        </div>
      </footer>
      <RequestCallback
        isOpen={modal}
        toggle={toggle}
        title="Request a call"
        message="I want to know more about your company!"
      />
    </>
  );
}

export default Footer;
