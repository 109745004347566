import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import RequestCallback from "../Common/RequestCallback";
import { ReactTyped } from "react-typed";

import "./Home.css";

// Image Imports

import aboutHome from "../../../images/about-home.jpg";
import wcu_affordable from "../../../images/wcu_affordable.jpg";
import wcu_experienced from "../../../images/wcu_experienced.jpg";
import wcu_personalized from "../../../images/wcu_personalized.jpg";
import home_security from "../../../images/home_security.gif";
import wcu_customer from "../../../images/wcu_customer.jpg";
import whatsappIcon from "../../../images/whatsapp.svg";
import consulting from "../../../images/consulting.gif";
import star_icon from "../../../images/star_icon.png";

// End of Image Imports

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Fish2shark - Canada's Favourite Marketing Agency | The Ultimate Business Solution for your Dream Business | Fish2shark Inc., Ontario, CA`;
  }, []);

  // Modal

  const [modal, setModal] = useState(false);

  //  Toggle Modal

  const toggle = () => setModal(!modal);

  const services = [
    {
      name: "Personalized Design, Direction and Distribution of Flyers",
      iconName: "book-open",
      desc: `The heart of business is marketing and that is
      where our part comes where we can design a
      eye-catching flyer for you, print and distribute
      in bulk as per your needs to your target audience.`,
    },
    {
      name: "Programmatic Advertisement with Ad Banners",
      iconName: "laptop",
      desc: `A brilliant video can get the attention of a
      potential customer very quickly as psychology
      suggests. We offer product Photography,
      Videography and we also create content for marketing videos.`,
    },
    {
      name: "Marketing Campaign Jingles, Podcast Ads and Publishing",
      iconName: "music",
      desc: `A catchy jingle of any brand tend to reverberate
      in our ears making an impact. We can compose a fitting Jingle 
      for your brand your audience will love bringing prominence to your business.`,
    },
  ];

  const wcu = [
    {
      title: "Affordable Pricing",
      imgURL: wcu_affordable,
      desc: `Our prices are always competitive in the market as we know how
      hard it is to build a business and we value your dreams. Keeping our 
      prices affordable and not compromising on the quality of the service 
      is our key.`,
    },
    {
      title: "Experienced Professionals",
      imgURL: wcu_experienced,
      desc: `We have experienced professional sales people to take your brand
      to the masses. They will do your job while you sit back and
      relax reaping the benefits of our campaign making your
      experience a walk in the park.`,
    },
    {
      title: "Personalized Solutions",
      imgURL: wcu_personalized,
      desc: `The best services are always customized precisely complimenting
      your business needs. We can provide you highly customized
      service ranging from strategies, plans and course of action to
      reach the target.`,
    },
    {
      title: "Customer-Centric Approach",
      imgURL: wcu_customer,
      desc: `We always adhere to a customer-centric approach in which we understand your requirement, plan and strategize with personalized inputs and propose our solutions accordingly for the best experience possible.`,
    },
  ];

  return (
    <main>
      <section className="home-wrapper">
        <div className="container">
          {/* Home Security */}
          {/* <div className="home-security-banner">
            <div className="container">
              <div className="hsb-left">
                <img src={home_security} alt="Home Security" />
                <div className="hsb-text">
                  <span>Introducing</span>
                  <h4>Fish2shark Home Security Services</h4>
                  <h6>Patrol Services for your Neighborhood</h6>
                </div>
              </div>
              <div className="hsb-right">
                <Link to="/homesecurity">
                  <button>Know more</button>
                </Link>
              </div>
            </div>
          </div> */}
          {/* End of Home Security */}
          <Row>
            <Col sm="12" lg="8" md="8">
              <div className="text-content">
                <h1>
                  Looking to<span>advertise</span>your
                </h1>
                <ReactTyped
                  strings={["Businesses", "Brands", "Professional Services"]}
                  typeSpeed={40}
                  loop
                  backSpeed={25}
                  showCursor={true}
                  backDelay={3000}
                />
                <h1>in Canada? You are in the right place!</h1>
                <h6>
                  Fish2shark is the only end-to-end advertising and marketing
                  solution you will ever need to grow your business across North
                  America.
                </h6>
                <div className="hero-cta-wrap">
                  {/* <input
                    type="email"
                    className="hero-email-input"
                    placeholder="Enter your Email Address"
                  /> */}
                  <a href="tel:+15488883355">
                    <button className="hero-cta-btn">
                      <i class="bx bx-phone-outgoing"></i>
                      Call us
                    </button>
                  </a>
                  <a
                    href="https://wa.me/+15488883355"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="hero-cta-btn whatsapp">
                      <img src={whatsappIcon} alt="WhatsApp" />
                      WhatsApp
                    </button>
                  </a>
                </div>
              </div>
            </Col>
            <Col sm="12" lg="4" md="4">
              {/* Services */}
              <section className="services-wrap section-wrapper">
                <div className="container">
                  <div className="serv-right">
                    <div className="serv-item-wrap">
                      {services.map((item) => {
                        return (
                          <Link to="services">
                            <div className="serv-item">
                              <div className="serv-front">
                                <div className="serv-icon">
                                  <box-icon
                                    name={item.iconName}
                                    type="regular"
                                    color="#fff"
                                  ></box-icon>
                                </div>
                                <h4>{item.name}</h4>
                              </div>
                              <i class="bx bx-right-arrow-alt arrow-cta"></i>
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </section>
            </Col>
          </Row>
          <div className="cta-arrow-down">
            <box-icon
              type="solid"
              color="#fff"
              size="lg"
              animation="fade-down"
              name="chevrons-down"
            ></box-icon>
            <span>Scroll for more</span>
          </div>
        </div>
        {/* <video className="home-wrapper-video" autoPlay loop muted playsInline>
          <source src={main_video} type="video/mp4" />
        </video>
        <div className="home-wrapper-video-overlay"></div> */}
      </section>
      {/* Consulting */}
      <section className="home-consulting-wrap">
        <div className="container">
          <div className="cw-left">
            <img
              src={consulting}
              alt="Consulting"
              data-mdb-lazy-load-init
              data-mdb-lazy-src
            />
            <div>
              <h4>
                Get <span>Business Consulting</span> worth CA$ 49 for{" "}
                <span>absolutely free!</span>
              </h4>
            </div>
          </div>
          <Link onClick={toggle}>
            <button className="consult-btn">
              <i class="bx bx-conversation"></i>
              Get Free Consulting
            </button>
          </Link>
        </div>
      </section>
      {/* Why Choose Us */}
      <section className="why-choose-us-wrap section-wrapper common-wrapper">
        <div className="container">
          <Row>
            <Col sm="12" lg="6" md="6">
              <div className="common-header">
                <h6>Choose us to make a difference</h6>
                <h2>
                  Why <span>Fish2shark?</span>
                </h2>
                <p>
                  We at Fish2shark are focused to provide the best service out
                  there with personalized marketing solutions along with free
                  consulting and long-term support. We stand apart from the
                  market in offering you a complete package of service ranging
                  from advertising and marketing plans, strategy, design,
                  content creation, videography and photography without injuring
                  your pockets making us the ultimate marketing solution for
                  your dream business in North America.
                </p>
              </div>
              <Link to="/services" className="d-inline-block">
                <button className="section-btn">
                  View our services
                  <i class="bx bxs-right-arrow-alt"></i>
                </button>
              </Link>
            </Col>
            <Col sm="12" lg="6" md="6">
              <div className="wcu-item-wrap">
                <Row>
                  {wcu.map((item) => {
                    return (
                      <Col sm="12" lg="6" md="6">
                        <div className="wcu-item">
                          <div className="wcu-img">
                            <img
                              src={item.imgURL}
                              alt="Affordable"
                              data-mdb-lazy-load-init
                              data-mdb-lazy-src
                            />
                          </div>
                          <h5>{item.title}</h5>
                          <div className="wcu-back">
                            <p>{item.desc}</p>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* About */}
      <section className="about-wrap section-wrapper">
        <div className="container">
          <Row>
            <Col sm="12" lg="6" md="6">
              <div className="abt-img-left">
                <img
                  src={aboutHome}
                  alt="About"
                  data-mdb-lazy-load-init
                  data-mdb-lazy-src
                />
              </div>
            </Col>
            <Col sm="12" lg="6" md="6">
              <div className="about-wrap-right">
                <h6>Our Story</h6>
                <h2>
                  About <span>Us</span>
                </h2>
                <p>
                  As a cohesive unit, Fish2shark was initiated in Ontario,
                  Canada with an intention to provide the ultimate marketing
                  solution in North America. Our prime goal is to make a
                  early-bird startup survive the competition which aptly
                  describes our name, taking a fish in the world of business and
                  making them a shark to stand out amongst their competitors. We
                  offer a wide variety of service in Canada ranging from
                  Consulting, Advertising, Content Creation, Product Shoot,
                  Videography, Personalized Flyers and many more. We provide a
                  hassle-free end-to-end service and an experience you will
                  love!
                </p>
                <Link to="/about" className="d-inline-block">
                  <button className="section-btn">
                    Know More
                    <i class="bx bxs-right-arrow-alt"></i>
                  </button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* FAQ */}
      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-item-wrap">
          <div className="faq-item">
            <h4>What is the response time for my enquiry?</h4>
            <h6>
              We will try our level best to respond to your queries as soon as
              possible. It generally takes a maximum time of 2 - 3 business
              days.
            </h6>
          </div>
          <div className="faq-item">
            <h4>Can I customize my schedule for the consultancy?</h4>
            <h6>
              Yes, of course! You can customize your schedule when you opt for
              our consultancy as per your desired timeframe. You just need to
              inform us before 24 hours and we will do the needful.
            </h6>
          </div>
          <div className="faq-item">
            <h4>
              How can I know the status of my application when I apply for an
              internship with you?
            </h4>
            <h6>
              We will contact you at the earliest if you're shortlisted or you
              can contact us anytime to our WhatsApp or Email to know the status
              of your application.
            </h6>
          </div>
          <div className="faq-item">
            <h4>Which is the right service for me?</h4>
            <h6>
              We will understand your need and suggest the best service for you.
              Our experts will walk you through all the services helping you
              pick the perfect service that caters to your business needs. You
              can also choose multiple products to avail special discounts!
            </h6>
          </div>
        </div>
      </section>
      {/* Rating */}
      <section className="rating-section">
        <div className="container">
          <div className="rating-text">
            <h2>
              We have over 300 happy clients and look what they have rated us on
              average
            </h2>
            <h5>
              <img src={star_icon} alt="Star" />
              4.8 / 5
            </h5>
          </div>
        </div>
      </section>
      <RequestCallback
        isOpen={modal}
        toggle={toggle}
        title="Get Free Consulting"
        message="I want to know more about your company!"
      />
    </main>
  );
}

export default Home;
