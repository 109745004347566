import React from "react";
import "./App.css";
import AppToaster from "./components/AppToaster";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Components Import

import Home from "./components/pages/Home/Home";
import About from "./components/pages/About/About";
import Contact from "./components/pages/Contact/Contact";
import Services from "./components/pages/Services/Services";
import Careers from "./components/pages/Careers/Careers";
import HomeSecurity from "./components/pages/HomeSecurity/HomeSecurity";

// Common Components

import Header from "./components/pages/Common/Header";
import Footer from "./components/pages/Common/Footer";
import FloatingMenu from "./components/pages/Common/FloatingMenu";
import Preloader from "./components/pages/Common/Preloader";
import GoToTop from "./components/pages/Common/GoToTop";

// Terms

import Legal from "./components/pages/Legal/Legal";

import PageNotFound from "./components/pages/Common/PageNotFound";

// End of Components Import

function App() {
  return (
    <div className="App">
      <FloatingMenu />
      <BrowserRouter>
        <AppToaster />
        <Preloader />
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/careers" element={<Careers />} />
          <Route exact path="/homesecurity" element={<HomeSecurity />} />
          <Route exact path="/legal" element={<Legal />} />
          <Route exact path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
        <GoToTop />
      </BrowserRouter>
    </div>
  );
}

export default App;
