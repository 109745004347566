import React from "react";
import { Row, Col } from "reactstrap";
import "./HomeSecurity.css";
import Breadcrumbs from "../Common/Breadcrumbs";
import Consulting from "../Common/Consulting";

// Image Imports

import home_security from "../../../images/home_security.jpg";
import hs_report from "../../../images/hs_report.svg";
import hs_visit from "../../../images/hs_visit.svg";
import hs_threat from "../../../images/hs_threat.svg";
import hs_emergency from "../../../images/hs_emergency.svg";

// End of Image Imports

function HomeSecurity() {
  return (
    <>
      <Breadcrumbs name="Home Security Service" />
      <section className="home-security-wrapper">
        <div className="container">
          <div className="hs-content">
            <Row>
              <Col sm="12" lg="6" md="6">
                <div className="hsw-text">
                  <h4>Patrol Services for your Neighborhood</h4>
                  <h2>Fish2shark Home Security Services</h2>
                  <h3>Service currently Available only in London, Ontario, CA</h3>
                  <h6>
                    You may sleep peacefully knowing that your life, vehicles,
                    and valuables are protected from criminals at night by
                    Ontario Security Licensed Professionals.
                  </h6>
                  <a
                    className="hs-enquire"
                    href="tel:+15488883355"
                    rel="noreferrer noopener"
                  >
                    <button className="section-btn">
                      <box-icon
                        type="solid"
                        name="phone-call"
                        animation="tada"
                        size="md"
                      ></box-icon>
                      Enquire now
                    </button>
                  </a>
                  <h5 className="hsw-head">Highlights of our service</h5>
                  <div className="hsw-highlight-item-wrap">
                    <div className="hsw-item">
                      <div className="hsw-img">
                        <img src={hs_report} alt="Report" />
                      </div>
                      <h5>Regular Reporting</h5>
                    </div>
                    <div className="hsw-item">
                      <div className="hsw-img">
                        <img src={hs_visit} alt="Visit" />
                      </div>
                      <h5>Regular Patrol Visits</h5>
                    </div>
                    <div className="hsw-item">
                      <div className="hsw-img">
                        <img src={hs_threat} alt="Threat" />
                      </div>
                      <h5>Reduce Threats</h5>
                    </div>
                    <div className="hsw-item">
                      <div className="hsw-img">
                        <img src={hs_emergency} alt="Emergency Help" />
                      </div>
                      <h5>Emergency Help at Nights</h5>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="12" lg="6" md="6">
                <div className="hsw-image">
                  <img src={home_security} className="hs_img" alt="Home" />
                </div>
              </Col>
            </Row>
          </div>
          <Consulting message="I want to know more about home security services!" />
        </div>
      </section>
    </>
  );
}

export default HomeSecurity;
